import { useState } from 'react';
import Mailbox from '@/components/mailbox';
import OpenInAppDialog from '@/components/openInAppDialog';
import NavMailsIcon from '@/components/svg/navMailsIcon';
import NavMessagesIcon from '@/components/svg/navMessagesIcon';
import NavSearchIcon from '@/components/svg/navSearchIcon';
import UserActions from '@/components/userActions';
import { createFileRoute, Link, Outlet } from '@tanstack/react-router';
import { Toaster } from 'react-hot-toast';
import { cn } from '@/lib/utils';

export const Route = createFileRoute('/_authenticated/_layout')({
  component: MainLayout,
});

function MainLayout() {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isDropdownOpen) {
      setIsHovered(false);
    }
  };

  const handleDropdownOpenChange = (open: boolean) => {
    setDropdownOpen(open);
    if (!open) {
      setIsHovered(false);
    }
  };

  const resetHeaderHeight = () => {
    setIsHovered(false);
  };

  const headerHeight = isHovered || isDropdownOpen ? 'h-[4.25rem]' : 'h-6';
  const headerHoverEffect =
    'opacity-0 transition-opacity duration-150 group-hover/header:opacity-100' +
    (isDropdownOpen ? ' opacity-100' : ' opacity-0');

  return (
    <div className='space-y-3 overflow-hidden'>
      <header
        className={cn(
          'group/header inset-y fixed left-0 right-0 top-0 z-50 border-b-2 bg-card',
          headerHeight,
          'transition-[width, transform] overflow-y-hidden duration-150'
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className='container grid grid-cols-11 grid-rows-1 items-center'>
          <nav
            className={cn(
              'col-span-5 col-start-4 justify-self-center',
              headerHoverEffect
            )}
          >
            <ul className='flex justify-center fill-muted-foreground text-center font-semibold text-muted-foreground'>
              <OpenInAppDialog
                onClose={resetHeaderHeight}
                url='https://talkytimes.com/search'
              >
                <li className='w-20 cursor-pointer space-y-1 py-2 transition-colors duration-300 hover:bg-muted hover:fill-foreground hover:text-foreground'>
                  <div className='flex justify-center'>
                    <NavSearchIcon className='size-8 rounded-full bg-muted p-2' />
                  </div>
                  <p className='text-sm'>Search</p>
                </li>
              </OpenInAppDialog>
              <Link to='/chat'>
                <li className='w-20 space-y-1 fill-primary py-2 text-primary transition-colors duration-300 hover:bg-muted hover:fill-foreground hover:text-foreground'>
                  <div className='flex justify-center'>
                    <NavMessagesIcon className='size-8 rounded-full bg-muted p-2' />
                  </div>
                  <p className='text-sm'>Messages</p>
                </li>
              </Link>
              <Mailbox indicator onClose={resetHeaderHeight}>
                <li className='w-20 cursor-pointer space-y-1 py-2 transition-colors duration-300 hover:bg-muted hover:fill-foreground hover:text-foreground'>
                  <div className='flex justify-center'>
                    <NavMailsIcon className='size-8 rounded-full bg-muted p-2' />
                  </div>
                  <p className='text-sm'>Mailbox</p>
                </li>
              </Mailbox>
            </ul>
          </nav>
          <UserActions
            className={cn(
              'col-span-3 col-start-9 justify-self-end',
              headerHoverEffect
            )}
            onOpenChange={handleDropdownOpenChange}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </header>
      <Outlet />
      <Toaster position='bottom-right' />
    </div>
  );
}
