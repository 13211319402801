import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import mailCountersQueryOptions from '@/lib/queries/mailCounters';
import { GirlProfile } from '@/lib/types/profiles';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Button } from './ui/button';
import DataTable from './ui/data-table';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

type MailCount = {
  profile: GirlProfile;
  count: number;
};

const columns: ColumnDef<MailCount>[] = [
  {
    id: 'avatar',
    header: 'Avatar',
    cell: ({ row }) => {
      const profile = row.original.profile;

      return (
        <Avatar>
          <AvatarImage src={profile.personal.avatar_large} />
          <AvatarFallback>{profile.name}</AvatarFallback>
        </Avatar>
      );
    },
  },
  {
    accessorKey: 'profile.name',
    header: () => <div className='text-center'>Name</div>,
  },
  {
    accessorKey: 'count',
    header: () => <div className='text-center'>Count</div>,
  },
  {
    id: 'goto',
    header: () => <div className='text-center'>Open</div>,
    cell: ({ row }) => {
      const { mutate, isPending } = useOpenInAppMutation(
        'https://talkytimes.com/mails/inbox/unanswered'
      );

      return (
        <Button
          variant='outline'
          onClick={() => mutate(row.original.profile.id)}
          disabled={isPending}
        >
          Go to
        </Button>
      );
    },
  },
];

export default function Mailbox({
  children,
  indicator,
  onClose = () => {},
}: {
  children: React.ReactNode;
  indicator?: boolean;
  onClose?: () => void;
}) {
  const { data: mailData } = useQuery(mailCountersQueryOptions);
  const { data: girlsData } = useQuery(girlsProfilesQueryOptions);

  const data = girlsData?.reduce((acc, profile) => {
    if (!mailData) return acc;

    const count = mailData[profile.id.toString()];

    if (typeof count === 'number') acc.push({ profile, count });

    return acc;
  }, [] as MailCount[]);

  return (
    <Dialog
      onOpenChange={isOpen => {
        if (!isOpen) onClose();
      }}
    >
      <DialogTrigger asChild>
        {indicator && mailData?.totalCount ? (
          <div className='relative'>
            {children}
            <div className='absolute right-0 top-0 text-lg font-bold text-red-600'>
              {mailData.totalCount}
            </div>
          </div>
        ) : (
          children
        )}
      </DialogTrigger>
      <DialogContent className='max-w-min'>
        <DialogHeader>
          <DialogTitle>Unanswered</DialogTitle>
        </DialogHeader>
        <div className='max-h-[80vh] w-min overflow-y-auto text-center'>
          {data && <DataTable columns={columns} data={data} />}
        </div>
        <DialogFooter className='text-lg font-semibold'>
          Total count: {mailData?.totalCount}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
