import giftUrl from '@/assets/gift-box-svgrepo-com.svg';
import winkUrl from '@/assets/wink.svg';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { DialogData, MessageData } from '@/lib/types/dialog';
import { ProfileData } from '@/lib/types/profiles';
import { cn } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import LetterMessage from './letterMessage';
import PhotoMessage from './photoMessage';
import CheckIcon from './svg/checkIcon';
import DoubleCheckIcon from './svg/doubleCheckIcon';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Button } from './ui/button';
import UnsupportedMessage from './unsupportedMessage';
import { VideoMessageContent } from './videoMessage';

function MessageContent({
  dialogData,
  messageData,
}: {
  dialogData: DialogData;
  messageData: MessageData;
}) {
  switch (messageData.type) {
    case 'message':
      return <span>{messageData.content.message}</span>;
    case 'sticker':
      return (
        <img
          className='h-32 w-32'
          src={messageData.content.url}
          alt='Sticker'
        />
      );
    case 'likephoto':
      return (
        <div className='text-sm text-card-foreground/90'>
          👍 Liked the photo
          <div className='flex items-stretch gap-2 pl-1 pt-1'>
            <div className='w-1 bg-card-foreground/20'></div>
            <img
              className='h-36 w-36 rounded-md'
              src={messageData.content.url}
              alt=''
            />
          </div>
        </div>
      );
    case 'like_newsfeed_post':
      return (
        <div className='text-sm text-card-foreground/90'>
          👍 Liked the newsfeed post
          <div className='flex items-stretch gap-2 pl-1 pt-1'>
            <div className='w-1 bg-card-foreground/20'></div>
            <div className='text-xs'>
              {messageData.content.text}
              {messageData.content.photos[0] && (
                <img
                  className='h-36 w-36 rounded-md'
                  src={messageData.content.photos[0].url}
                  alt='Post media preview'
                />
              )}
            </div>
          </div>
        </div>
      );
    case 'wink':
      return (
        <>
          <img src={winkUrl} alt='Wink' />
          <div className='text-sm text-muted-foreground'>Winked</div>
        </>
      );
    case 'virtual_gift':
      return (
        <div className='flex flex-col items-center gap-2'>
          <img src={giftUrl} alt='' className='h-40 w-40' />
          <Button variant='outline'>Open</Button>
        </div>
      );
    case 'system':
      return (
        <div className='rounded-lg bg-blue-100 px-3 py-2 text-center'>
          {messageData.content.message}
        </div>
      );
    case 'photo':
    case 'photo_batch':
      return <PhotoMessage dialogData={dialogData} messageData={messageData} />;
    case 'video':
      return <VideoMessageContent videoMessageData={messageData} />;

    case 'correspondence_letter':
      return (
        <LetterMessage dialogData={dialogData} messageData={messageData} />
      );

    case 'audio':
      return (
        <audio
          controls
          src={messageData.content.urlOgg}
          className='rounded-md opacity-90'
        />
      );

    default:
      return (
        <UnsupportedMessage dialogData={dialogData} messageData={messageData} />
      );
  }
}

export default function Message({
  dialogData,
  messageData,
  interlocutorProfileData,
  shouldDisplayAvatar,
}: {
  dialogData: DialogData;
  messageData: MessageData;
  interlocutorProfileData: ProfileData;
  shouldDisplayAvatar?: boolean;
}) {
  const girlsProfiles = useQuery(girlsProfilesQueryOptions).data;

  if (messageData.type === 'system')
    return (
      <div className='mx-auto w-2/3'>
        <MessageContent dialogData={dialogData} messageData={messageData} />
      </div>
    );

  const isFromMe = dialogData.idUser === messageData.idUserFrom;

  const time = new Intl.DateTimeFormat('de', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(Date.parse(messageData.dateCreated));

  const type = messageData.type;

  return (
    <div
      className={cn(
        'flex items-end gap-2',
        isFromMe ? 'flex-row-reverse self-end' : ''
      )}
    >
      <div className='w-10 shrink-0'>
        {shouldDisplayAvatar && (
          <Avatar>
            <AvatarImage
              src={
                isFromMe
                  ? girlsProfiles?.find(
                      profile => profile.id === dialogData.idUser
                    )?.personal.avatar_large
                  : interlocutorProfileData.personal.avatar_large
              }
              alt='Avatar'
            />
            <AvatarFallback>Interlocutor's avatar</AvatarFallback>
          </Avatar>
        )}
      </div>
      {type === 'photo' ||
      type === 'photo_batch' ||
      type === 'correspondence_letter' ? (
        <div className='space-y-1'>
          <MessageContent dialogData={dialogData} messageData={messageData} />
          {isFromMe ? (
            <div className='flex justify-end'>
              <div className='flex items-center justify-end gap-0.5 rounded-full bg-slate-400 fill-background px-2 py-0.5 text-sm text-white'>
                {time}
                {dialogData.idInterlocutorLastReadMsg >= messageData.id ? (
                  <DoubleCheckIcon />
                ) : (
                  <CheckIcon className='h-7 w-7' />
                )}
              </div>
            </div>
          ) : (
            <div className='flex'>
              <div className='flex rounded-full bg-slate-400 px-2 py-0.5 text-white'>
                <div>{time}</div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={cn(
            'min-w-0 max-w-[720px] break-words rounded-lg p-2',
            isFromMe ? 'bg-lime-100' : 'bg-background'
          )}
        >
          <MessageContent dialogData={dialogData} messageData={messageData} />
          {isFromMe ? (
            <div className='flex items-center justify-end gap-1 pt-1 text-sm text-muted-foreground'>
              {time}
              {dialogData.idInterlocutorLastReadMsg >= messageData.id ? (
                <DoubleCheckIcon className='fill-green-600' />
              ) : (
                <CheckIcon className='h-7 w-7 fill-muted-foreground' />
              )}
            </div>
          ) : (
            <div className='flex pt-1 text-sm text-muted-foreground'>
              {time}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
