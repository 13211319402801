import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import useBookmarkMutation from '@/lib/mutations/bookmark';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import dialogQueryOptions from '@/lib/queries/dialog';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import profileQueryOptions from '@/lib/queries/profile';
import {
  BookmarkFilledIcon,
  BookmarkIcon,
  DrawingPinFilledIcon,
  DrawingPinIcon,
  ExclamationTriangleIcon,
  MinusCircledIcon,
} from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import ItemContent from './itemContent';
import ItemSkeleton from './itemSkeleton';
import { cn } from '@/lib/utils';

function BookmarkMenuItem({
  idUser,
  idInterlocutor,
  isBookmarked,
}: {
  idUser: number;
  idInterlocutor: number;
  isBookmarked: boolean;
}) {
  const { mutate } = useBookmarkMutation(idUser, idInterlocutor);

  return (
    <ContextMenuItem onSelect={() => mutate(!isBookmarked)}>
      {isBookmarked ? (
        <>
          <BookmarkFilledIcon />
          <span className='pl-1'>Unsave</span>
        </>
      ) : (
        <>
          <BookmarkIcon />
          <span className='pl-1'>Save</span>
        </>
      )}
    </ContextMenuItem>
  );
}

export default function DialogListItem({
  refProp,
  idUser,
  idInterlocutor,
  isOnline,
  selected,
}: {
  refProp?: (element: any) => void;
  idUser: number;
  idInterlocutor: number;
  isOnline: boolean;
  selected?: boolean;
}) {
  const client = useQueryClient();

  const profileData = useQuery(
    profileQueryOptions(client, idInterlocutor)
  ).data;

  const dialogData = useQuery(
    dialogQueryOptions(client, idUser, idInterlocutor)
  ).data;

  useQuery({
    ...byPairsQueryOptions(client, idInterlocutor, idUser),
    enabled: !(dialogData && profileData),
  });

  const girlProfile = useQuery(girlsProfilesQueryOptions).data?.find(
    profile => profile.id === idUser
  );

  if (!profileData || !girlProfile || !dialogData)
    return (
      <Link
        to='/chat/$dialogId'
        params={{
          dialogId: `${idUser}_${idInterlocutor}`,
        }}
      >
        <ItemSkeleton />
      </Link>
    );

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Link
          to='/chat/$dialogId'
          params={{
            dialogId: `${idUser}_${idInterlocutor}`,
          }}
        >
          <div className={cn('w-48', selected && 'bg-primary/20')}>
            <ItemContent
              refProp={refProp}
              girlProfileData={girlProfile}
              interlocutorProfileData={profileData}
              dialogData={dialogData}
              isOnline={isOnline}
            />
          </div>
        </Link>
      </ContextMenuTrigger>
      <ContextMenuContent>
        {dialogData.isPinned ? (
          <ContextMenuItem>
            <DrawingPinFilledIcon />
            <span className='pl-1'>Unpin</span>
          </ContextMenuItem>
        ) : (
          <ContextMenuItem>
            <DrawingPinIcon />
            <span className='pl-1'>Pin</span>
          </ContextMenuItem>
        )}
        <BookmarkMenuItem
          idUser={idUser}
          idInterlocutor={idInterlocutor}
          isBookmarked={dialogData.isBookmarked}
        />
        <ContextMenuItem>
          <ExclamationTriangleIcon />
          <span className='pl-1'>Report</span>
        </ContextMenuItem>
        <ContextMenuItem>
          <MinusCircledIcon />
          <span className='pl-1'>Block</span>
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
