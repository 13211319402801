import type { FC } from 'react';

import { cn } from '@/lib/utils';

interface FavoriteEmojiBarProps {
  favorites: string[];
  onRemoveFavorite: (emoji: string) => void;
  onSelectFavorite: (emoji: string) => void;
}

const FavoriteEmojiBar: FC<FavoriteEmojiBarProps> = ({
  favorites,
  onRemoveFavorite,
  onSelectFavorite,
}) => {
  if (favorites.length === 0) return null;
  return (
    <div
      className={cn(
        'h-8 px-3 py-2',
        'flex items-center gap-1 rounded-md shadow-sm',
        'border border-input bg-transparent'
      )}
    >
      {favorites.map((emoji, index) => (
        <div
          key={index}
          className='text-md cursor-pointer'
          onClick={() => onSelectFavorite(emoji)}
          onContextMenu={e => {
            e.preventDefault();
            onRemoveFavorite(emoji);
          }}
        >
          {emoji}
        </div>
      ))}
    </div>
  );
};

export default FavoriteEmojiBar;
