import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { isAdminQueryOptions } from '@/lib/queries/isAdmin';
import EmployeesSettings from '@/modules/employeesSettings';
import GirlsSettings from '@/modules/girlsSettings';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_layout/settings')({
  component: Settings,
});

function Settings() {
  const { data } = useQuery(isAdminQueryOptions);

  return (
    <main className='container flex justify-center pt-6'>
      {data && !data.admin && (
        <div className='w-[30rem] space-y-2 pb-2'>
          <GirlsSettings />
        </div>
      )}
      {data && data.admin && (
        <Tabs defaultValue='employees' className='w-[30rem]'>
          <TabsList className='w-full'>
            <TabsTrigger value='employees' className='grow font-semibold'>
              Employees
            </TabsTrigger>
            <TabsTrigger value='girls' className='grow font-semibold'>
              Girls
            </TabsTrigger>
          </TabsList>
          <TabsContent value='employees' className='space-y-2 pb-2'>
            <EmployeesSettings />
          </TabsContent>
          <TabsContent value='girls' className='space-y-2 pb-2'>
            <GirlsSettings />
          </TabsContent>
        </Tabs>
      )}
    </main>
  );
}
