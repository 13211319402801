import emojisIconUrl from '@/assets/emojisIcon.svg';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Close } from '@radix-ui/react-popover';
import { useState } from 'react';
import { createPortal } from 'react-dom';

const emojis: { category: string; list: string }[] = [
  { category: 'Frequently used', list: '👍👎😭😕😐😊😍' },
  {
    category: 'People',
    list:
      '😄😃😀😊😉😍😘😚😗😙😜😝😛😳😁😔😌😒😞😣😢😂😭😪😥😰😅😓😩😫😨😱😠😡😤😖😆😋😷😎😴😵😲😟😦😧👿😮😬😐😕😯😏😑👲👳👮👷💂👶👦👧👨👩👴👵👱👼👸😺😸😻😽😼🙀😿😹😾👹👺🙈🙉🙊💀👽💩🔥✨🌟' +
      '💫💥💢💦💧💤💨👂👀👃👅👄👍👎👌👊✊👋✋👐👆👇👉👈🙌🙏👏💪🚶🏃💃👫👪💏💑👯🙆🙅💁🙋💆💇💅👰🙎🙍🙇🎩👑👒👟👞👡👠👢👕👔👚👗🎽👖👘👙💼👜👝👛👓🎀🌂💄💛💙💜💚💔💗💓💕💖💞💘💌💋💍💎👤💬👣',
  },
  {
    category: 'Nature',
    list: '🐶🐺🐱🐭🐹🐰🐸🐯🐨🐻🐷🐽🐮🐗🐵🐒🐴🐑🐘🐼🐧🐦🐤🐥🐣🐔🐍🐢🐛🐝🐜🐞🐌🐙🐚🐠🐟🐬🐳🐎🐲🐡🐫🐩🐾💐🌸🌷🍀🌹🌻🌺🍁🍃🍂🌿🌾🍄🌵🌴🌰🌱🌼🌑🌓🌔🌕🌛🌙🌏🌋🌌🌠⛅⛄🌀🌁🌈🌊',
  },
  {
    category: 'Objects',
    list:
      '🎍💝🎎🎒🎓🎏🎆🎇🎐🎑🎃👻🎅🎄🎁🎋🎉🎊🎈🎌🔮🎥📷📹📼💿📀💽💾💻📱📞📟📠📡📺📻🔊🔔📢📣⏳⌛⏰⌚🔓🔒🔏🔐🔑' +
      '🔎💡🔦🔌🔋🔍🛀🚽🔧🔩🔨🚪🚬💣🔫🔪💊💉💰💴💵💳💸📲📧📥📤📩📨📫📪📮📦📝📄📃📑📊📈📉📜📋📅📆📇📁📂📌📎📏' +
      '📐📕📗📘📙📓📔📒📚📖🔖📛📰🎨🎬🎤🎧🎼🎵🎶🎹🎻🎺🎷🎸👾🎮🃏🎴🀄🎲🎯🏈🏀⚽⚾🎾🎱🎳⛳🏁🏆🎿🏂🏊🏄🎣🍵🍶🍺🍻' +
      '🍸🍹🍷🍴🍕🍔🍟🍗🍖🍝🍛🍤🍱🍣🍥🍙🍘🍚🍜🍲🍢🍡🍳🍞🍩🍮🍦🍨🍧🎂🍰🍪🍫🍬🍭🍯🍎🍏🍊🍒🍇🍉🍓🍑🍈🍌🍍🍠🍆🍅🌽',
  },
  {
    category: 'Places',
    list: '🏠🏡🏫🏢🏣🏥🏦🏪🏩🏨💒⛪🏬🌇🌆🏯🏰⛺🏭🗼🗾🗻🌄🌅🌃🗽🌉🎠🎡⛲🎢🚢⛵🚤🚀💺🚉🚄🚅🚇🚃🚌🚙🚗🚕🚚🚨🚓🚒🚑🚲💈🚏🎫🚥🚧🔰⛽🏮🎰🗿🎪🎭📍🚩',
  },
  {
    category: 'Symbols',
    list:
      '💯🔢🔟🔣🔠🔡🔤🔼🔽⏪⏩⏫⏬🆗🆕🆙🆒🆓🆖📶🎦🈁🈯🈳🈵🈴🈲🉐🈹🈺🈶🈚🚻🚹🚺🚼🚾🚭🈸🉑🆑🆘🆔🚫🔞⛔❎✅💟🆚📳📴' +
      '🆎💠⛎🔯🏧💹💲💱❌❗❓❕❔⭕🔝🔚🔙🔛🔜🔃🕛🕐🕑🕒🕓🕔🕕🕖🕗🕘🕙🕚➕➖➗💮🔘🔗➰🔱🔺🔲🔳🔴🔵🔻⬜⬛🔶🔷🔸🔹',
  },
];

function mapEmojiList(
  emojis: string,
  setText: (value: React.SetStateAction<string>) => void,
  onRightClick: (emoji: string) => void
) {
  const list: JSX.Element[] = [];

  for (const emoji of emojis) {
    list.push(
      <button
        key={emoji}
        onClick={() => setText(prev => prev + emoji)}
        onContextMenu={e => {
          e.preventDefault();
          onRightClick(emoji);
        }}
      >
        {emoji}
      </button>
    );
  }
  return list;
}

function PickerContent({
  setText,
  onRightClick,
}: {
  setText: (value: React.SetStateAction<string>) => void;
  onRightClick: (emoji: string) => void;
}) {
  return (
    <div className='font-semibold'>
      <div className='flex items-center justify-between bg-muted p-2'>
        <div>EMOJIS</div>
        <Close>
          <Cross2Icon className='h-5 w-5' />
        </Close>
      </div>
      <div className='h-64 space-y-2 overflow-y-scroll p-2'>
        {emojis.map(set => (
          <div key={set.category}>
            <div className='text-sm'>{set.category}</div>
            <div className='flex flex-wrap gap-1 text-lg tracking-widest'>
              {mapEmojiList(set.list, setText, onRightClick)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function EmojiPicker({
  setText,
  portalContainer,
  onRightClick,
}: {
  setText: (value: React.SetStateAction<string>) => void;
  portalContainer: HTMLElement | null;
  onRightClick: (emoji: string) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button className='' variant='outline'>
          <img src={emojisIconUrl} alt='' />
          Emojis
        </Button>
      </PopoverTrigger>
      <PopoverContent className='hidden'>
        {portalContainer &&
          createPortal(
            <PickerContent setText={setText} onRightClick={onRightClick} />,
            portalContainer
          )}
      </PopoverContent>
    </Popover>
  );
}
