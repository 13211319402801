import { useQuery } from '@tanstack/react-query';

export function useFavoriteEmojisQuery(idUser: string | number) {
  return useQuery({
    queryKey: ['emojis', 'favorites', idUser],
    queryFn: async () => {
      const res = await fetch(`/api/v1/chat/emojis`, {
        method: 'GET',
      });
      if (!res.ok) throw new Error('Error fetching favorite emojis');
      return res.json() as Promise<{ emojis: string[] }>;
    },
  });
}
