import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useSendFavoriteEmojisMutation(idUser: string | number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (updatedEmojis: string[]) => {
      const res = await fetch(`/api/v1/chat/emojis`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emojis: updatedEmojis }),
      });
      if (!res.ok) throw new Error('Error updating favorite emojis');
      return updatedEmojis;
    },
    onSuccess: updatedEmojis => {
      queryClient.setQueryData(['emojis', 'favorites', idUser], {
        emojis: updatedEmojis,
      });
    },
  });
}
