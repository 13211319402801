import { UseQueryOptions } from '@tanstack/react-query';

type EntityType = 'photo' | 'video';

export type SentStatus<T extends EntityType> = {
  status: 'sent' | 'accessed' | null;
} & (T extends 'photo' ? { idPhoto: number } : { idVideo: number });

type SentStatusResponse<T extends EntityType> = T extends 'photo'
  ? { photos: SentStatus<T>[] }
  : { videos: SentStatus<T>[] };

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const res: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res;
}

export default function sentStatusQueryOptions<T extends EntityType>(
  entity: T,
  idUser: number,
  idInterlocutor: number,
  idsMedia: number[]
) {
  return {
    queryKey: ['sentStatus', { entity, idUser, idInterlocutor }, idsMedia],
    queryFn: async (): Promise<SentStatusResponse<T>> => {
      if (idsMedia.length === 0) {
        const emptyResponse =
          entity === 'photo'
            ? { photos: [] as SentStatus<T>[] }
            : { videos: [] as SentStatus<T>[] };

        return emptyResponse as SentStatusResponse<T>;
      }

      const chunks = chunkArray(idsMedia, 40);

      const promises = chunks.map(chunk =>
        fetch(
          entity === 'photo'
            ? '/api/v1/gallery/photo/connection/list'
            : '/api/v1/gallery/video/connection/list',
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              idUser: idInterlocutor,
              limit: 40,
              idUserGirl: idUser,
              [entity === 'photo' ? 'idsPhotos' : 'idsVideos']: chunk,
            }),
          }
        ).then(res => res.json())
      );

      const res = await Promise.all(promises);

      return res.reduce(
        (acc, response) => {
          if (entity === 'photo') {
            acc.photos = [...acc.photos, ...(response.photos || [])];
          } else {
            acc.videos = [...acc.videos, ...(response.videos || [])];
          }
          return acc;
        },
        entity === 'photo' ? { photos: [] } : { videos: [] }
      ) as SentStatusResponse<T>;
    },
    gcTime: 0,
  } satisfies UseQueryOptions;
}
