import mediaIconUrl from '@/assets/mediaIcon.svg';
import PhotoCarousel from '@/components/photoCarousel';
import ApprovedMediaIcon from '@/components/svg/approvedMediaIcon';
import DeclinedMediaIcon from '@/components/svg/declinedMediaIcon';
import { Button, ButtonProps } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import type { MediaFilter, PhotoData, VideoData } from '@/lib/types/media';
import { cn } from '@/lib/utils';
import {
  ClockIcon,
  EyeOpenIcon,
  PaperPlaneIcon,
  SizeIcon,
} from '@radix-ui/react-icons';
import { forwardRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from '@tanstack/react-query';
import useSendPhotoMutation from './api/mutations/sendPhoto';
import useVideoMutation from './api/mutations/sendVideo';
import useGalleryInfiniteQuery from '@/lib/queries/gallery';
import sentStatusQueryOptions, { SentStatus } from './api/queries/sentStatus';
import AudioGrid from './audioGrid';

const attachPhotoLimit = 6;

interface SortButtonProps extends ButtonProps {
  active?: boolean;
}

function StatusBadge({
  data,
  className,
}: {
  data:
    | (PhotoData & { sentStatus?: SentStatus<'photo'> })
    | (VideoData & { sentStatus?: SentStatus<'video'> });

  className: string;
}) {
  let bgColor: string;
  let text: 'approved' | 'declined' | 'on moderation';

  const status = data.status.code;

  if (status === 'declined' || status === 'declined_by_ai') {
    bgColor = 'bg-red-600';
    text = 'declined';
  } else if (data.tags.some(tag => tag.code === 'erotic')) {
    bgColor = 'bg-secondary-foreground/60';
    text = 'approved';
  } else if (status === 'on_moderation') {
    bgColor = 'bg-orange-500';
    text = 'on moderation';
  } else {
    bgColor = 'bg-green-500';
    text = 'approved';
  }

  return (
    <div
      className={cn('flex flex-col items-start gap-1 text-white', className)}
    >
      {data.sentStatus?.status && (
        <div className='rounded-full bg-foreground/70 p-2'>
          {data.sentStatus.status === 'sent' ? (
            <PaperPlaneIcon className='size-4' />
          ) : (
            <EyeOpenIcon className='size-4' />
          )}
        </div>
      )}
      <div
        className={cn(
          'flex items-center gap-1 rounded-full px-2 py-1 text-sm',
          bgColor
        )}
      >
        {text === 'approved' && (
          <ApprovedMediaIcon className='h-5 w-5 fill-white' />
        )}
        {text === 'on moderation' && (
          <ClockIcon className='h-5 w-5 fill-white' />
        )}
        {text === 'declined' && (
          <DeclinedMediaIcon className='h-5 w-5 fill-white' />
        )}
        {text}
      </div>
    </div>
  );
}

const MediaView = forwardRef<
  HTMLDivElement,
  {
    data:
      | (PhotoData & { sentStatus?: SentStatus<'photo'> })
      | (VideoData & { sentStatus?: SentStatus<'video'> });
    attached: boolean;
    onClick: () => void | undefined;
    onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  }
>(({ data, attached, onClick, onButtonClick }, ref) => {
  const erotic = data.tags.some(tag => tag.code === 'erotic');

  const approved =
    data.status.code === 'approved' || data.status.code === 'approved_by_ai';

  const available = !erotic && approved;

  const url = 'idPhoto' in data ? data.urls.urlPreview : data.urls.urlThumbnail;

  return (
    <div
      ref={ref}
      className={cn('relative h-44')}
      onClick={available ? onClick : undefined}
    >
      <img
        src={url}
        alt=''
        className={cn(
          'h-full w-full rounded-md bg-green-100 object-cover',
          !available && 'opacity-60',
          attached && 'border-4 border-green-500 p-2'
        )}
      />
      {'idPhoto' in data && (
        <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
          <button
            className='rounded-full border border-white p-1'
            onClick={e => {
              e.stopPropagation();
              if (onButtonClick) onButtonClick(e);
            }}
          >
            <SizeIcon className='h-8 w-8 text-white' />
          </button>
        </div>
      )}
      <StatusBadge data={data} className='absolute bottom-1 left-2' />
    </div>
  );
});

function PhotoGrid({
  idUser,
  idInterlocutor,
  setOpen,
  filterPhoto,
}: {
  idUser: number;
  idInterlocutor: number;
  setOpen: (open: boolean) => void;
  filterPhoto: MediaFilter;
}) {
  const [index, setIndex] = useState<number | null>(null);

  const { data, fetchNextPage, isFetching } = useGalleryInfiniteQuery(
    'photo',
    idUser,
    filterPhoto
  );

  const { data: sentStatus } = useQuery(
    sentStatusQueryOptions(
      'photo',
      idUser,
      idInterlocutor,
      data?.pages.flatMap(page => page.photos.map(photo => photo.idPhoto)) ?? []
    )
  );

  const { mutate, isPending } = useSendPhotoMutation(idUser, idInterlocutor);

  const [attached, setAttached] = useState(() => new Set<number>());

  const { ref } = useInView({
    threshold: 0.8,
    onChange: inView => {
      if (inView && !isFetching) fetchNextPage();
    },
  });

  const photosData = data?.pages.flatMap(page => page.photos);

  const photos = photosData?.map((photo, i, arr) => (
    <MediaView
      key={photo.idPhoto}
      ref={i === arr.length - 1 ? ref : undefined}
      data={{
        ...photo,
        sentStatus: sentStatus?.photos.find(s => s.idPhoto === photo.idPhoto),
      }}
      attached={attached.has(photo.idPhoto)}
      onClick={() => {
        if (!attached.has(photo.idPhoto)) {
          if (attached.size === attachPhotoLimit) return;

          attached.add(photo.idPhoto);
          setAttached(new Set(attached));
        } else {
          attached.delete(photo.idPhoto);
          setAttached(new Set(attached));
        }
      }}
      onButtonClick={() => setIndex(i)}
    />
  ));

  return (
    <div className='space-y-2'>
      <div className='grid h-[60vh] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] gap-2 overflow-y-auto 3xl:h-[720px]'>
        {photos}
      </div>
      <div className='flex justify-end'>
        <Button
          className='gap-1 font-bold'
          disabled={attached.size === 0 || isPending}
          onClick={() =>
            mutate(
              photosData?.filter(photo => attached.has(photo.idPhoto)) || [],
              { onSuccess: () => setOpen(false) }
            )
          }
        >
          Send
          <PaperPlaneIcon />
        </Button>
      </div>
      {photosData && (
        <PhotoCarousel
          index={index}
          setIndex={setIndex}
          images={photosData.map(photo => ({
            id: photo.idPhoto,
            url: photo.urls.urlStandard,
          }))}
        />
      )}
    </div>
  );
}

function VideoGrid({
  idUser,
  idInterlocutor,
  setOpen,
  filterVideo,
}: {
  idUser: number;
  idInterlocutor: number;
  setOpen: (open: boolean) => void;
  filterVideo: MediaFilter;
}) {
  const { data, fetchNextPage, isFetching } = useGalleryInfiniteQuery(
    'video',
    idUser,
    filterVideo
  );

  const { data: sentStatus } = useQuery(
    sentStatusQueryOptions(
      'video',
      idUser,
      idInterlocutor,
      data?.pages.flatMap(page => page.videos.map(video => video.idVideo)) ?? []
    )
  );

  const { mutate, isPending } = useVideoMutation(idUser, idInterlocutor);

  const [attached, setAttached] = useState(() => new Set<number>());

  const { ref } = useInView({
    threshold: 0.8,
    onChange: inView => {
      if (inView && !isFetching) fetchNextPage();
    },
  });

  const videosData = data?.pages.flatMap(page => page.videos);

  const videos = videosData?.map((video, i, arr) => (
    <MediaView
      key={video.idVideo}
      ref={i === arr.length - 1 ? ref : undefined}
      data={{
        ...video,
        sentStatus: sentStatus?.videos.find(s => s.idVideo === video.idVideo),
      }}
      attached={attached.has(video.idVideo)}
      onClick={() => {
        if (!attached.has(video.idVideo)) {
          attached.add(video.idVideo);
          setAttached(new Set(attached));
        } else {
          attached.delete(video.idVideo);
          setAttached(new Set(attached));
        }
      }}
    />
  ));

  return (
    <div className='space-y-2'>
      <div className='grid h-[60vh] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] gap-2 overflow-y-auto 3xl:h-[720px]'>
        {videos}
      </div>
      <div className='flex justify-end'>
        <Button
          className='gap-1 font-bold'
          disabled={attached.size === 0 || isPending}
          onClick={() => {
            videosData
              ?.filter(video => attached.has(video.idVideo))
              .map(video => mutate(video, { onSuccess: () => setOpen(false) }));
          }}
        >
          Send
          <PaperPlaneIcon />
        </Button>
      </div>
    </div>
  );
}

const SortButton = ({ active, children, ...props }: SortButtonProps) => {
  return (
    <button
      className={cn(
        'bg-muted text-xl text-muted-foreground',
        'inline-flex items-center justify-center whitespace-nowrap',
        'rounded-md px-3 py-1 font-medium ring-offset-background transition-colors',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        'disabled:pointer-events-none disabled:opacity-50',
        active && 'bg-background text-foreground shadow'
      )}
      onClick={props.onClick}
    >
      {children}
    </button>
  );
};

export default function MediaPicker({
  idUser,
  idInterlocutor,
}: {
  idUser: number;
  idInterlocutor: number;
}) {
  const [open, setOpen] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState<
    'photo' | 'video' | 'audio'
  >('photo');
  const [activeFilter, setActiveFilter] = useState<MediaFilter>('approved');

  const filterButtonsTitle = ['All', 'Approved', 'Erotic', 'Special+'];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='' variant='outline'>
          <img src={mediaIconUrl} alt='' />
          Media
        </Button>
      </DialogTrigger>
      <DialogContent className='max-w-[70vw] 3xl:max-w-[1700px]'>
        <DialogHeader className='flex items-center justify-between'>
          <DialogTitle className='text-2xl font-bold'>
            Media Gallery
          </DialogTitle>
        </DialogHeader>
        <Tabs
          defaultValue='photo'
          onValueChange={value =>
            setActiveFilterTab(value as 'photo' | 'video' | 'audio')
          }
        >
          <div className='flex items-center justify-between'>
            <TabsList>
              <TabsTrigger className='text-xl' value='photo'>
                Photo
              </TabsTrigger>
              <TabsTrigger className='text-xl' value='video'>
                Video
              </TabsTrigger>
              <TabsTrigger className='text-xl' value='audio'>
                Audio
              </TabsTrigger>
            </TabsList>
            <div className='flex gap-2'>
              {activeFilterTab !== 'audio' && (
                <div className='flex gap-2'>
                  {filterButtonsTitle
                    .filter(
                      button =>
                        !(activeFilterTab === 'video' && button === 'Special+')
                    )
                    .map(button => (
                      <SortButton
                        key={button}
                        active={
                          activeFilter ===
                          button
                            .toLowerCase()
                            .replace('+', '')
                            .replace('special', 'special_plus')
                        }
                        onClick={() =>
                          setActiveFilter(
                            button
                              .toLowerCase()
                              .replace('+', '')
                              .replace('special', 'special_plus') as MediaFilter
                          )
                        }
                      >
                        {button}
                      </SortButton>
                    ))}
                </div>
              )}
            </div>
          </div>
          <TabsContent value='photo'>
            <PhotoGrid
              idUser={idUser}
              idInterlocutor={idInterlocutor}
              setOpen={setOpen}
              filterPhoto={activeFilter}
            />
          </TabsContent>
          <TabsContent value='video'>
            <VideoGrid
              idUser={idUser}
              idInterlocutor={idInterlocutor}
              setOpen={setOpen}
              filterVideo={activeFilter}
            />
          </TabsContent>
          <TabsContent value='audio'>
            <AudioGrid
              idUser={idUser}
              idInterlocutor={idInterlocutor}
              setOpen={setOpen}
            />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}
