import OnlineIndicator from '@/components/onlineIndicator';
import CheckIcon from '@/components/svg/checkIcon';
import DoubleCheckIcon from '@/components/svg/doubleCheckIcon';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import messageContentString from '@/lib/messageContentString';
import typingQueryOptions from '@/lib/queries/typing';
import { DialogData } from '@/lib/types/dialog';
import { GirlProfile, ProfileData } from '@/lib/types/profiles';
import { cn } from '@/lib/utils';
import { BookmarkFilledIcon, CountdownTimerIcon } from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export type ChatNavItemProps = {
  refProp?: (element: any) => void;
  girlProfileData: GirlProfile;
  interlocutorProfileData: ProfileData;
  dialogData: DialogData;
  isOnline: boolean;
};

export default function ItemContent({
  refProp,
  girlProfileData,
  interlocutorProfileData,
  dialogData,
  isOnline,
}: ChatNavItemProps) {
  const date = new Date(dialogData.dateUpdated);

  const lastUpdateTime =
    date.toDateString() === new Date().toDateString()
      ? `${date.getHours().toString().padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : new Intl.DateTimeFormat('de', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }).format(date);

  const isLastFromMe = dialogData.idUser === dialogData.lastMessage.idUserFrom;

  const client = useQueryClient();

  const { data } = useQuery(
    typingQueryOptions(client, girlProfileData.id, interlocutorProfileData.id)
  );

  return (
    <div
      ref={refProp}
      className={cn(
        'flex w-full items-center gap-2 px-1 py-2',
        dialogData.highlightType === 'limit_reload' && 'bg-green-100'
      )}
    >
      <div className='flex items-center gap-0.5 rounded-lg border bg-muted p-1'>
        <div className='w-[5.75rem] truncate text-center'>
          {girlProfileData.name}
        </div>
        <Avatar>
          <AvatarImage
            src={girlProfileData.personal.avatar_large}
            alt="Girl's avatar"
          />
          <AvatarFallback>{girlProfileData.name}</AvatarFallback>
        </Avatar>
      </div>

      <div className='relative'>
        <Avatar className='h-12 w-12'>
          <AvatarImage
            src={interlocutorProfileData.personal.avatar_large}
            alt='avatar'
          />
          <AvatarFallback>{interlocutorProfileData.name}</AvatarFallback>
        </Avatar>
        <OnlineIndicator
          className='absolute -right-1 bottom-0'
          isOnline={isOnline}
        />
      </div>

      <div className='w-full'>
        <div className='flex gap-1'>
          <div className='flex items-center justify-start font-semibold text-card-foreground/90'>
            <div className='max-w-16 truncate'>
              {interlocutorProfileData.name}
            </div>
            <div className='whitespace-nowrap'>
              {', '}
              {interlocutorProfileData.personal.age}
            </div>
          </div>
          {dialogData.isBookmarked && (
            <BookmarkFilledIcon className='flex-shrink-0 self-center' />
          )}
          <div className='ml-auto flex items-center gap-1'>
            {isLastFromMe &&
              (dialogData.idInterlocutorLastReadMsg ===
              dialogData.lastMessage.id ? (
                <DoubleCheckIcon className='fill-green-600' />
              ) : (
                <CheckIcon className='fill-muted-foreground' />
              ))}
            <div className='text-sm text-muted-foreground'>
              {lastUpdateTime}
            </div>
          </div>
        </div>

        <div className='flex'>
          <div
            className={cn(
              'w-48 truncate text-sm text-muted-foreground',
              data && 'text-primary'
            )}
          >
            {data ? (
              'typing...'
            ) : dialogData.isBlocked ? (
              <span className='font-semibold text-red-600'>User blocked</span>
            ) : (
              messageContentString(dialogData)
            )}
          </div>
          {isLastFromMe && dialogData.highlightType === 'limit_reload' ? (
            <div className='ml-auto leading-snug'>
              <CountdownTimerIcon className='h-5 w-5 text-green-600' />
            </div>
          ) : null}
          {!isLastFromMe &&
            (dialogData.idLastReadMsg !== dialogData.lastMessage.id ? (
              <div className='h-5 w-5 flex-shrink-0 flex-grow-0 rounded-full bg-primary text-center text-sm leading-snug text-background'>
                1
              </div>
            ) : dialogData.highlightType === 'limit_reload' ? (
              <div className='ml-auto leading-snug'>
                <CountdownTimerIcon className='h-5 w-5 text-green-600' />
              </div>
            ) : (
              <div className='h-5 w-5 flex-shrink-0 flex-grow-0 rounded-full bg-muted-foreground text-center text-sm leading-snug text-background'>
                !
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
