import logoutMutationOptions from '@/lib/mutations/logout';
import usernameQueryOptions from '@/lib/queries/username';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

export default function UserActions({
  className,
  onOpenChange,
  onMouseLeave,
}: {
  className?: string;
  onOpenChange: (isOpen: boolean) => void;
  onMouseLeave: () => void;
}) {
  const client = useQueryClient();

  const { data } = useQuery(usernameQueryOptions());
  const { mutate } = useMutation(logoutMutationOptions(client));

  return (
    <div className={className} onMouseLeave={onMouseLeave}>
      <DropdownMenu onOpenChange={onOpenChange}>
        <DropdownMenuTrigger className='text-lg font-semibold'>
          {data}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to='/settings'>
            <DropdownMenuItem className='cursor-pointer text-lg'>
              Settings
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem
            className='cursor-pointer text-lg'
            onClick={() => mutate()}
          >
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
