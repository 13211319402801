import { MediaFilter, PhotoData, VideoData } from '@/lib/types/media';
import { useInfiniteQuery } from '@tanstack/react-query';

type EntityType = 'photo' | 'video';

type GalleryData<T extends EntityType> = { cursor: string } & (T extends 'photo'
  ? { photos: PhotoData[] }
  : { videos: VideoData[] });

export default function useGalleryInfiniteQuery<T extends EntityType>(
  entity: T,
  idUser: number,
  filter?: MediaFilter
) {
  return useInfiniteQuery({
    queryKey: ['gallery', entity, idUser, filter],
    queryFn: async ({ pageParam }) => {
      const body = {
        cursor: pageParam,
        limit: 40,
        statuses:
          filter === 'approved'
            ? entity === 'video'
              ? ['approved']
              : ['approved', 'approved_by_ai']
            : [],
        tags:
          filter === 'erotic'
            ? ['erotic']
            : filter === 'special_plus'
            ? entity === 'video'
              ? []
              : ['special_plus']
            : [],
        excludeTags:
          filter === 'approved'
            ? [
                'erotic',
                ...(entity === 'photo' ? ['special', 'special_plus'] : []),
              ]
            : filter === 'erotic'
            ? []
            : filter === 'special_plus'
            ? []
            : [],
      };

      const res = await fetch(
        `/api/v1/gallery/${entity}/list?idUser=${idUser}`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            ...body,
            ...(entity === 'photo' && { idAlbum: null, idAlbumExcluded: null }),
          }),
        }
      );

      return res.json() as Promise<GalleryData<T>>;
    },
    initialPageParam: '',
    getNextPageParam: lastPage => (lastPage.cursor ? lastPage.cursor : null),
    gcTime: 0,
  });
}
